
import './App.css';

import { Route, Routes, useLocation } from 'react-router-dom';

import { Modal } from 'react-bootstrap';

import VenuesPage from './pages/VenuesPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import VendorsPage from './pages/VendorsPage';
import VendorDetailsPage from './pages/VendorDetailsPage';
import ItemDetailsPage from './pages/ItemDetailsPage';
import ErrorPage from './pages/ErrorPage';
import PasswordResetPage from './pages/PasswordResetPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import CartsPage from './pages/CartsPage';
import CartDetailsPage from './pages/CartDetailsPage';
import StitchReturnPage from './pages/StitchReturnPage';
import QueuesPage from './pages/QueuesPage';
import PrintInvoicePage  from './pages/print';
import ProfilePage from './pages/ProfilePage';
import { AuthGaurd } from './gaurds/AuthGaurd';
import Toast from './common/Toast';
import { useDispatch, useSelector } from 'react-redux';
import { toggleShowAuthModal } from './redux/features/auth/authSlice';
import Auth from './components/Auth';
import { useEffect } from 'react';
import { fetchActiveVenueAction } from './redux/features/app/globalActions';
import { fetchActiveCartAction } from './redux/features/cart/cartActions';
import { fetchActiveQueuesAction } from './redux/features/queues/queuesActions';
import Pusher from "pusher-js";
import Echo from 'laravel-echo';

const App = () => {
  const dispatch = useDispatch();

  const { activeVenueId } = useSelector((state) => state.global);
  const { user, showAuthModal, context, token } = useSelector((state) => state.auth);

  const handleClose = () => dispatch(toggleShowAuthModal());

  const location = useLocation();
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.update();
      });
    }
  }, [location]);

  useEffect(() => {
    if (activeVenueId){
      dispatch(fetchActiveVenueAction({id: activeVenueId}));
    }
  }, [dispatch, activeVenueId]);

  useEffect(() => {
    if (activeVenueId && user){
      dispatch(fetchActiveCartAction({user_id: user.id, venue_id: activeVenueId}));
      dispatch(fetchActiveQueuesAction(context));
    }  
  }, [dispatch, activeVenueId, user, context]);

  useEffect(() => {
    window.Pusher = Pusher;

    const options = {
      broadcaster: "pusher",
      key: process.env.REACT_APP_PUSHER_APP_KEY,
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      forceTLS: process.env.REACT_APP_PUSHER_APP_SCHEME === 'https',
      encrypted: false,
      authEndpoint: `${process.env.REACT_APP_API_URL}/broadcasting/auth`,
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json"
        }
      }
    };

    console.log(options);
    
    const echo = new Echo(options);
    
    if (user) {
      console.log(context, 'context');
      if (context.type === 'Vendor') {
        console.log(`Subscribe to vendors.${context.data.id}`);

        echo.private(`vendors.${context.data.id}`)
          .listen('QueueOrderAdded', (order) => {
            // Refresh the queue
            dispatch(fetchActiveQueuesAction(context));

            // Send push notification if supported and permission has been granted.
            if (('Notification' in window)) {
              if (Notification.permission === 'granted'){
                navigator.serviceWorker.getRegistration().then((reg) => {

                  console.log("Sending notification via service worker!");
                  console.log(order, 'order');

                  var options = {
                    // body: 'Your order is ready to collect!',
                    icon: '/logo192.png',
                    vibrate: [100, 50, 100],
                    image: order.vendor.image ?? order.vendor.image
                  };

                  reg.showNotification(`A new order has been added to your queue.`, options);
                });
              }
            }
          });
      } else {
        echo.private(`users.${user.id}`)
          .listen('QueueOrderStateUpdated', (order) => {
            console.log(order, 'order');

            // Refresh the queue
            dispatch(fetchActiveQueuesAction(context));

            // Send push notification if supported and permission has been granted.
            if (('Notification' in window)) {
              if (Notification.permission === 'granted' && order?.queue?.state === 'done'){
                navigator.serviceWorker.getRegistration().then((reg) => {

                  console.log("Sending notification via service worker!");
                  console.log(order, 'order');

                  var options = {
                    // body: 'Your order is ready to collect!',
                    icon: '/logo192.png',
                    vibrate: [100, 50, 100],
                    image: order.vendor.image ?? order.vendor.image
                  };

                  reg.showNotification(`Your order at ${order.vendor.name} is ready to collect!`, options);
                });
              }
            }
          });
      }


    } else {
      echo.leaveAllChannels();
    }
  }, [user, context, token, dispatch]);

  return (
      <div >
          <Routes>
              <Route path='/' element={<VenuesPage />} />
              
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route path="/password-reset/:token" element={<PasswordResetPage />} />

              <Route path="/venues" element={<VenuesPage />} />
              <Route path="/venues/:venueId" >
                <Route path="/venues/:venueId/vendors" element={<VendorsPage />} />
                <Route path="/venues/:venueId/vendors/:vendorId" element={<VendorDetailsPage />} />

                <Route path="/venues/:venueId/vendors/:vendorId/items/:itemId" element={<ItemDetailsPage />} />
              </Route>

              <Route element={<AuthGaurd user={user} />}>
                
                <Route path="/venues/:venueId" >
                  <Route path="/venues/:venueId/carts" element={<CartsPage />} />
                  <Route path="/venues/:venueId/queues" element={<QueuesPage />} />
                 
                  <Route path="/venues/:venueId/cart" element={<CartDetailsPage />} />
                  <Route path="/venues/:venueId/profile" element={<ProfilePage />} />
                </Route>
                <Route path="/order/:orderId/print" element={<PrintInvoicePage />} />
                <Route path="/profile" element={<ProfilePage />} />

                <Route path="/stitch/return" element={<StitchReturnPage />} />
              </Route>

              <Route path="*" element={<ErrorPage />} />
          </Routes>

          <Toast ></Toast>
          
          <Modal className='modal fade modalbox' show={showAuthModal} onHide={handleClose}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
              <Auth ></Auth>
            </Modal.Body>
            <Modal.Footer >
              &nbsp;
            </Modal.Footer>
          </Modal>
      </div>
  );
}

export default App;
