import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { selectCartItemCount } from "../redux/features/cart/cartSlice";

const BottomMenu = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  // New state for tracking if scrolled
  const [isScrolled, setIsScrolled] = useState(false);

  let { venueId } = useParams();
  const { context } = useSelector((state) => state.auth);
  const cartItemCount = useSelector(selectCartItemCount);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 20;
      setIsScrolled(isScrolled);
    };

    // Attaching the event listener
    window.addEventListener("scroll", handleScroll);

    // Detaching the event listener on unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, []); // Empty dependency array ensures this runs on mount and unmount only

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const getLink = () => {
    if (venueId && venueId.includes("undefined")) {
      return `/venues`;
    }
    return `/venues/${venueId}/vendors`;
  };

  return (
    <div className="appBottomMenu">
      <button
        className={`btn btn-primary p-0 shadow-lg rounded-circle text-white ${
          isScrolled ? "" : "d-none"
        }`}
        style={{
          position: "fixed",
          bottom: "70px",
          right: "10px",
          width: "40px",
          height: "40px",
        }}
        onClick={scrollToTop}
      >
        <ion-icon name="chevron-up-outline"></ion-icon>
      </button>
      {context.type === "User" ? (
        <Fragment>
          {venueId ? (
            <NavLink to={getLink()} className="item">
              <div onClick={() => setActiveIndex(0)} className="nav-item">
                <div className="col">
                  <ion-icon name={`fast-food`}></ion-icon>
                  <strong>Vendors</strong>
                </div>
              </div>
            </NavLink>
          ) : (
            <NavLink to={`/venues`} className="item">
              <div onClick={() => setActiveIndex(0)} className="nav-item">
                <div className="col">
                  <ion-icon name={`Location`}></ion-icon>
                  <strong>Venues</strong>
                </div>
              </div>
            </NavLink>
          )}

          {venueId && (
            <NavLink to={`/venues/${venueId}/cart`} className="item">
              <div onClick={() => setActiveIndex(1)} className="nav-item">
                <div className="col">
                  <span className="badge badge-danger ">
                    {cartItemCount ? cartItemCount : ""}
                  </span>
                  <ion-icon name="bag-add"></ion-icon>
                  <strong>Cart</strong>
                </div>
              </div>
            </NavLink>
          )}

          {venueId && (
            <NavLink to={`/venues/${venueId}/queues`} className="item">
              <div onClick={() => setActiveIndex(2)} className="nav-item">
                <div className="col">
                  <ion-icon name="bag-check"></ion-icon>
                  <span className="badge badge-danger"></span>
                  <strong>Orders</strong>
                </div>
              </div>
            </NavLink>
          )}

          <NavLink to={`/venues/${venueId}/profile`} className="item">
            <div onClick={() => setActiveIndex(3)} className="nav-item">
              <div className="col">
                <ion-icon name="person-circle"></ion-icon>
                <strong>My Account</strong>
              </div>
            </div>
          </NavLink>
        </Fragment>
      ) : (
        <Fragment>
          <NavLink to={`/venues/${venueId}/queues`} className="item">
            <div onClick={() => setActiveIndex(0)} className="nav-item">
              <div className="col">
                <i className="bi bi-stack"></i>
                <span className="badge badge-danger"></span>
                <strong>Orders</strong>
              </div>
            </div>
          </NavLink>
          <NavLink to={`/venues/${venueId}/profile`} className="item">
            <div onClick={() => setActiveIndex(1)} className="nav-item">
              <div className="col">
                <ion-icon name="person-circle"></ion-icon>
                <strong>My Account</strong>
              </div>
            </div>
          </NavLink>
        </Fragment>
      )}
      <div className="hump" style={{ left: `${activeIndex * 25}%` }} />
    </div>
  );
};

export default BottomMenu;
