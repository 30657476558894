import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const registerUser = createAsyncThunk(
  'register',
  async ({ name, email, password, password_confirmation}, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      const { data } = await axios.post(
        '/register',
        { name, email, password, password_confirmation },
        config
      )

      // store user's token in local storage
      localStorage.setItem('token', data.token);
      localStorage.setItem('user', JSON.stringify(data.data));
      
      var context = {
        type: 'User',
        data: data.data
      };
      localStorage.setItem('context', JSON.stringify(context));

      return data
    } catch (error) {
        // return custom error message from backend if present
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message)
        } else {
            return rejectWithValue(error.message)
        }
    }
  }
)

export const loginAction = createAsyncThunk(
    'login',
    async ({ email, password }, { rejectWithValue }) => {
        try {
            // configure header's Content-Type as JSON
            const config = {
              headers: {
                  'Content-Type': 'application/json',
              },
            }
            
            const { data } = await axios.post(
              `/login`,
              { email, password },
              config
            )

            // store user's token in local storage
            localStorage.setItem('token', data.token);
            localStorage.setItem('user', JSON.stringify(data.data));
            
            var context = {
              type: 'User',
              data: data.data
            };
            localStorage.setItem('context', JSON.stringify(context));

            return data
        } catch (error) {
          console.log(error.response.data);

          // return custom error message from API if any
          if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message)
          } else {
          return rejectWithValue(error.message)
          }
        }
    }
)