import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const AppHeader = (props) => {
  const [scroll, setScroll] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const vh = window.innerHeight;
      const threshold = (vh * 10) / 100; 
      setScroll(window.scrollY > threshold);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

 

  return (
    <div
      className={`appHeader   p-1 ${
        scroll ? "header-toggle " : "bg-none"
      }`}
    >
    <div className="left ">
            <a href="!#" className="headerButton" data-bs-toggle="offcanvas" data-bs-target="#sidebarPanel">
                <ion-icon name="menu-outline"></ion-icon>
            </a>
        </div>
      <div className={` header-name pt-1 col-7   ${scroll ? "d-block " : "d-none"}`}>
       
          <h3 className="text-dark">{props.name}</h3>
       
      </div>
      <div className={`right`}>
       
        <div
          className= {scroll ? "d-block btn-back " : "btn-back-top"} 
          onClick={() => navigate(-1)}
        >
          <ion-icon
            name="chevron-back-outline"
            style={{ fontSize: "40px"}}
          ></ion-icon>
        </div>
      </div>
    </div>
  );
};

export default AppHeader;