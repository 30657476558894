import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../common/Footer";
import SidebarPanel from "../common/SidebarPanel";
import { useDispatch, useSelector } from "react-redux";
import api from "../services/api.service";
import AppHeader from "../common/AppHeader";
import Loader from "../common/Loader";
import BottomMenu from "../common/BottomMenu";
import { clearActiveVenue } from "../redux/features/app/globalSlice";

const VenuesPage = () => {
  const { user } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  const [venues, setVenues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);

    api
      .get("/venues")
      .then((response) => {
        setVenues(response.data.data);

        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);

        setIsLoading(false);
      });
  }, [navigate, user]);

  useEffect(() => {
    dispatch(clearActiveVenue());
  }, [dispatch]);

  return (
    <Fragment >
      <AppHeader name="Venues"></AppHeader>
      <div id="appCapsule ">
      <div className="venue-block">
            <div
              className="venue-inner"
           ></div>
          </div>
          <div className="header-text text-center">
              <h2>Venues</h2>
              <div className="header-description text-center">
                <hr />
              </div>
            </div>

        
        <div className="markets-body alt-bg ">
          {isLoading ? (
            <Loader />
          ) : (
            
            venues.map((venue) => {
              return (
                <div className="block-card my-3" key={venue.id}>
                  <Link to={`/venues/${venue.id}/vendors`}>      
                    <div className="">
                      <div className="block-inner"
                        style={{ backgroundImage: `url(${venue.image_url})` }}
                      ></div>
                      <div className="w-100 text-center block-text" >
                        <h3>{venue.name}</h3>
                        <hr className="" />
                        <p>{venue.blurb}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })
          )}
        </div>
      </div>
      <Footer />
      <BottomMenu name="Venues" />
      <SidebarPanel />
    </Fragment>
  );
};

export default VenuesPage;
