import React, { Fragment, useEffect, useState, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import BottomMenu from "../common/BottomMenu";
import Footer from "../common/Footer";
import SidebarPanel from "../common/SidebarPanel";
import { useDispatch, useSelector } from "react-redux";
import api from "../services/api.service";
import AppHeader from "../common/AppHeader";
import Loader from "../common/Loader";
import { fetchActiveQueuesAction } from "../redux/features/queues/queuesActions";
import { updateQueueItemState } from "../redux/features/queues/queuesSlice";
import TimeAgo from "react-timeago";

import ManageNotifications from "../common/ManageNotifications";

const QueuesPage = () => {
  const { venueId } = useParams();

  const [filter, setFilter] = useState("");

  const { user, context } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.queues.loading);
  const queues = useSelector((state) => state.queues.data);
  const [newQueues, setNewQueues] = useState([]);
  const [busyQueues, setBusyQueues] = useState([]);
  const [doneQueues, setDoneQueues] = useState([]);
  const [orders, setOrders] = useState([]);
  const [previousOrders, setPreviousOrders] = useState([]);

  const navigate = useNavigate();

  const setIsLoading = useCallback(
    (value) => {
      dispatch({ type: "queues/setIsLoading", payload: value });
    },
    [dispatch]
  );

  const getNextQueueStateLabel = (queueState) => {
    switch (queueState) {
      case "new":
        return "Pick";
      case "busy":
        return "Done";
      default:
        return "Collected";
    }
  };

  useEffect(() => {
    if (!user) {
      navigate("/login");
      return;
    }

    if (!venueId) {
      navigate("/venues");
      return;
    }

    dispatch(fetchActiveQueuesAction(context));

    api
      .get("/me", { params: { context } })
      .then((response) => {
        console.log("my orders", response.data.orders.data)
        setOrders(response.data.orders.data);
        setIsLoading(true);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, [user, navigate, context, dispatch, setIsLoading, venueId]);

  useEffect(() => {
    setNewQueues(queues.filter((queue) => queue.state === "new"));
    setBusyQueues(queues.filter((queue) => queue.state === "busy"));
    setDoneQueues(queues.filter((queue) => queue.state === "done"));

    const matchingOrders = orders.filter((order) =>
      queues.find((queue) => queue.order?.id !== order.id)
    );
    if(matchingOrders.length === 0){
      setPreviousOrders(orders);
    }else{
      setPreviousOrders(matchingOrders);
    }
   
  
  }, [queues, orders]);

  const handleOnNextQueueState = (e, queue) => {
    let queueState = null;

    switch (queue.state) {
      case "new":
        queueState = "busy";
        break;
      case "busy":
        queueState = "done";
        break;
      default:
        queueState = "collected";
        break;
    }

    api
      .put(`/queues/${queue.id}`, {
        state: queueState,
      })
      .then((response) => {
        dispatch(updateQueueItemState(response.data.data));
        console.log("queue state", response.data.data)
        if (queueState === "collected") {
          setFilter("");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const totalLength =
    (newQueues ? newQueues.length : 0) +
    (busyQueues ? busyQueues.length : 0) +
    (doneQueues ? doneQueues.length : 0);

  return (
    <Fragment>
      <AppHeader name="Orders"></AppHeader>

      <div id="appCapsule">
        {isLoading && !queues ? (
          <Loader />
        ) : (
          <Fragment>
            <div className="header-block">
              <div className="header-inner"></div>
            </div>
            <div className="header-text text-center">
              <h1>Orders</h1>
              <div className="header-description text-center">
                <hr />
              </div>
            </div>
            <ManageNotifications />

            {((newQueues && newQueues.length > 0) ||
              (busyQueues && busyQueues.length > 0) ||
              (doneQueues && doneQueues.length > 0)) ? (
              <div>
                <div className="row w-100 rounded shadow py-4 px-2">
                  <ul className="nav nav-tabs d-flex flex-row justify-content-around queues-nav">
                    <li className="nav-item col-3">
                      <button
                        className={`nav-link text-center w-100 p-2 ${
                          filter === "" ? " active " : "bg-secondary"
                        }`}
                        onClick={() => setFilter("")}
                      >
                        All
                        <br />
                        <span className="badge badge-dark">{totalLength}</span>
                      </button>
                    </li>
                    <li className="nav-item col-3">
                      <button
                        className={`nav-link  text-center p-2 w-100 ${
                          filter === "new" ? " active " : "bg-secondary"
                        }`}
                        onClick={() => setFilter("new")}
                      >
                        New
                        <br />
                        <span className="badge badge-dark ">
                          {newQueues.length}
                        </span>
                      </button>
                    </li>
                    <li className="nav-item col-3">
                      <button
                        className={`nav-link  text-center w-100 p-2  ${
                          filter === "busy" ? " active " : "bg-secondary"
                        }`}
                        onClick={() => setFilter("busy")}
                      >
                        Busy
                        <br />
                        <span className="badge badge-dark ">
                          {busyQueues.length}
                        </span>
                      </button>
                    </li>
                    <li className="nav-item col-3">
                      <button
                        className={`nav-link  p-2 w-100 ${
                          filter === "done" ? "  active" : "bg-secondary"
                        }`}
                        onClick={() => setFilter("done")}
                      >
                        Done
                        <br />
                        <span className="badge badge-dark ">
                          {doneQueues.length}
                        </span>
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="text-center p-3">
                  {" "}
                  <h1>
                    {filter === ""
                      ? "All Orders"
                      : filter === "new"
                      ? "New Orders"
                      : filter === "busy"
                      ? "Orders in Progress"
                      : "Completed Orders"}
                  </h1>
                </div>
              </div>
            ):(<div><div className="text-center p-3 pb-4 w-100 fs-3 fw-bold text-muted">You have no orders in the queue right now</div> <hr/> </div>)}
            <div className="section p-1">
              <div className="w-100  current-Items">
                {queues
                  .filter((queue) => (filter ? queue.state === filter : queue))
                  .map((queue) => {
                    return (
                      <div
                        className="card  col-sm-12  col-md-items m-1 rounded shadow p-0"
                        key={`queue-${queue.id}`}
                      >
                        <div className="">
                          <div
                            className="card-header queue-img"
                            style={{
                              backgroundImage: queue.order.vendor?.image_url
                                ? `url(${queue.order.vendor.image_url})`
                                : "none",
                            }}
                          >
                            <div className="w-100">
                              <div className="w-100 d-flex justify-content-end align-items-center pt-1">

                                <h5
                                  className={`fw-normal queue-text rounded-full p-1 d-inline text-white float-right text-capitalize ${
                                    queue.state === "new"
                                      ? "bg-custom-blue"
                                      : queue.state === "busy"
                                      ? "bg-custom-orange"
                                      : "bg-custom-green"
                                  }   px-3 py-1 d-inline`}
                                >
                                  &nbsp; {queue.state} &nbsp;
                                </h5>
                              </div>

                              <div className="w-100 pb-5 mt-md-3">
                                <h2 className="queue-text  text-white ">
                                  Order #{queue.order.order_number}
                                </h2>
                                <h4 className="queue-text text-white pb-3">
                                  {context.type !== "Vendor" ? (
                                
                                   queue.state === "new"
                                      ? "Thank you for your order, please collect when its is marked as done."
                                      : queue.state === "busy"
                                      ? "Your Order is in progress."
                                      : "Your order is completed, please collect, and show your your order number."
                                  
                                  ) : (
                                    
                                      queue.state === "new"
                                        ? ""
                                        : queue.state === "busy"
                                        ? "This order is being Processed."
                                        : "Waiting to be collected."
                                    
                                  )}
                                    
                                </h4>
                              </div>
                            </div>
                          </div>

                          <div className="card-body p-0">
                            <div className="w-100 px-2 pt-2">
                              {context.type === "Vendor" ? (
                                <span className="text-uppercase" >{queue.order.user.name}</span>
                              ) : (
                                <span className="text-uppercase" >{queue.order.vendor.name}</span>
                              )}
                              <br />
                              <span>ORDER #{queue.order.order_number}</span>
                              <br />
                              <span>
                                DATE:{" "}
                                {new Date(queue.created_at).toLocaleDateString(
                                  "en-ZA"
                                )}
                              </span><br />
                              <span>
                                TIME:{" "}
                                {new Date(queue.created_at).toLocaleTimeString(
                                  "en-ZA"
                                )}
                              </span>
                              
                            </div>
                            <hr />
                            <small className="fw-bold px-2">Items</small>
                            {queue.order.order_items &&
                            queue.order.order_items.data &&
                            queue.order.order_items.data.length > 0 ? (
                              <ul className="listview flush px-0 py-0 mb-5 pb-5">
                                {queue.order.order_items.data.map(
                                  (order_item) => {
                                    return (
                                      <li
                                        className="px-3"
                                        key={`order-item-${order_item.id}`}
                                      >
                                        <div className="row mb-1 px-2">
                                          <div className="col pt-2">
                                            <h3>
                                              {order_item.quantity} x{" "}
                                              {order_item.variant.item.name}
                                            </h3>
                                          </div>
                                          <div className="col text-end text-success pt-2">
                                            <b>R{order_item.total}</b>
                                          </div>
                                        </div>

                                        <div className="row mb-1">
                                          <div className="col">
                                            <div className="px-2">
                                              {order_item.variant.name}
                                            </div>
                                          </div>
                                        </div>

                                        {order_item.item_options &&
                                        order_item.item_options.data &&
                                        order_item.item_options.data.length > 0
                                          ? order_item.item_options.data.map(
                                              (item_option) => {
                                                return (
                                                  <div
                                                    className="row mb-1"
                                                    key={`item-option-${item_option.id}`}
                                                  >
                                                    <div className="col">
                                                      <span className="px-2 text-success">
                                                        <i className="bi bi-plus-circle"></i>{" "}
                                                        {
                                                          item_option.option
                                                            .name
                                                        }{" "}
                                                        <b>
                                                          R{item_option.price}
                                                        </b>
                                                      </span>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )
                                          : null}

                                        {order_item.notes && (
                                          <div className="row mb-1 px-4 mb-5">
                                            <div className="col">
                                              <b>NOTE: </b>
                                              <br />
                                              {order_item.notes}
                                            </div>
                                          </div>
                                        )}
                                      </li>
                                    );
                                  }
                                )}
                              </ul>
                            ) : null}
                          </div>
                          <div className="w-100 d-flex flex-row justify-content-between  pb-5 mb-5 mt-5"></div>
                          {context.type === "Vendor" &&
                          queue.state !== "done" ? (
                            <div className="card-footer  position-absolute bottom-0 w-100 d-flex  flex-column mb-0 mt-5">
                              <div className="w-100 d-flex  flex-column">
                                <div className="w-100 d-flex justify-content-between">
                                  <h3>Total</h3>
                                  <h3 class="text-success">
                                    R {queue.order.total}
                                  </h3>
                                </div>
                                <div className=" text-sm text-muted">
                                  Added{" "}
                                  <TimeAgo
                                    date={queue.created_at}
                                    units="second"
                                  />
                                </div>
                              </div>
                              <div className="w-100 mt-3">
                                <div className="w-100 d-flex justify-content-between">
                                  <div>
                                    <Link
                                      to={`/order/99897a7f-dd95-4250-bb8a-fb06b175b787/print?invoiceData=${encodeURIComponent(
                                        JSON.stringify({
                                          order: queue.order,
                                          created_at: queue.created_at,
                                        })
                                      )}`}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="btn btn-lg btn-primary"
                                    >
                                      Print
                                    </Link>
                                  </div>

                                  <div>
                                    <button
                                      className="btn  btn-lg btn-dark "
                                      onClick={(e) =>
                                        handleOnNextQueueState(e, queue)
                                      }
                                    >
                                      {getNextQueueStateLabel(queue.state)}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            context.type === "Vendor" && (
                              <div className="card-footer  position-absolute bottom-0 w-100 d-flex  flex-column mb-0 mt-5">
                                <div className="w-100 d-flex  flex-column">
                                  <div className="w-100 d-flex justify-content-between">
                                    <h3>Total</h3>
                                    <h3 class="text-success">
                                      R {queue.order.total}
                                    </h3>
                                  </div>
                                  <div className=" text-sm text-muted">
                                    Added{" "}
                                    <TimeAgo
                                      date={queue.created_at}
                                      units="second"
                                    />
                                  </div>
                                </div>
                                <div className="w-100 mt-3">
                                  <div className="w-100 d-flex justify-content-start">
                                    <div>
                                      <Link
                                        to={`/order/99897a7f-dd95-4250-bb8a-fb06b175b787/print?invoiceData=${encodeURIComponent(
                                          JSON.stringify({
                                            order: queue.order,
                                            created_at: queue.created_at,
                                          })
                                        )}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="btn btn-lg btn-primary"
                                      >
                                        Print
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )}

                          {context.type === "User" && (
                            <div className="card-footer  position-absolute bottom-0 w-100 pb-3 mt-5">
                              <div className="w-100 d-flex justify-content-between">
                                <h3>Total</h3>
                                <h3 class="text-success">
                                  R {queue.order.total}
                                </h3>
                              </div>
                              {queue.state === "done" ? (
                                <div className="text-sm text-muted">
                                  Ready to collect!
                                  <button
                                    className="btn btn-sm btn-primary float-end mt-3"
                                    data-bs-toggle="modal"
                                    data-bs-target={`#queues-${queue.id}`}
                                  >
                                    Collect
                                  </button>
                                  <div
                                    className="modal fade dialogbox"
                                    id={`queues-${queue.id}`}
                                    data-bs-backdrop="static"
                                    tabindex="-1"
                                    role="dialog"
                                  >
                                    <div
                                      className="modal-dialog"
                                      role="document"
                                    >
                                      <div className="modal-content">
                                        <div className="modal-header">
                                          <h5 className="modal-title">
                                            {queue.order.vendor.name}
                                          </h5>
                                        </div>
                                        <div className="modal-body">
                                          <h1>
                                            Order #{queue.order.order_number}
                                          </h1>
                                        </div>
                                        <div className="modal-footer">
                                          <div className="btn-inline">
                                            <a
                                              href="!#"
                                              className="btn btn-text-secondary"
                                              data-bs-dismiss="modal"
                                            >
                                              CLOSE
                                            </a>
                                            <a
                                              href="!#"
                                              className="btn btn-text-primary"
                                              onClick={(e) => {
                                                handleOnNextQueueState(e, queue);
                                                
                                              }}
                                              data-bs-dismiss="modal"
                                            >
                                              OK
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  <div className="text-sm text-muted">
                                    You are {queue.position_in_queue} in the
                                    queue for this vendor
                                  </div>
                                  <div className="  float-end ">
                                    <Link
                                      to={`/order/99897a7f-dd95-4250-bb8a-fb06b175b787/print?invoiceData=${encodeURIComponent(
                                        JSON.stringify({
                                          order: queue.order,
                                          created_at: queue.created_at,
                                        })
                                      )}`}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="btn btn-sm btn-primary"
                                    >
                                      Print
                                    </Link>
                                  </div>
                                </div>
                              )}
                              <div className="text-sm text-muted">
                                Added{" "}
                                <TimeAgo
                                  date={queue.created_at}
                                  units="second"
                                />
                              </div>
                              <small>
                                Created At:{" "}
                                {new Date(queue.created_at).toLocaleString(
                                  "en-GB"
                                )}
                              </small>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
              {filter === "" && previousOrders.length > 0 && (
                <div className="section my-3">
                  <div className="w-100 p-2 text-center">
                    <h1>Previous Orders</h1>
                  </div>
                  <div className="w-100   current-Items">
                    {previousOrders.map((order) => {
                      return (
                        <div
                          className="card col-sm-12 col-md-items m-1 rounded shadow p-0"
                          key={`previous-order-${order.id}`}
                        >
                          <div className="">
                            <div
                              className="card-header queue-img"
                              style={{
                                backgroundImage: order.vendor?.image_url
                                  ? `url(${order.vendor.image_url})`
                                  : "none",
                              }}
                            >
                              <div className="w-100 text-end pb-2 pt-1">
                                <h5 className="fw-normal queue-text rounded-full p-1 d-inline  float-right bg-custom-white px-3 py-1 d-inline">
                                  Collected
                                </h5>
                              </div>
                              <div>
                                <h2 className="text-white queue-text">
                                Order #{order.order_number}
                                </h2>
                                <h4 className="queue-text text-white">
                                Your order has been collected.
                                </h4>
                              </div>
                            </div>
                            <div className="card-body p-0">
                            <div className="w-100 px-2 pt-2">
                              {context.type === "Vendor" ? (
                                <span className="text-uppercase">{order.user.name}</span>
                              ) : (
                                <span className="text-uppercase">{order.vendor.name}</span>
                              )}
                              <br />
                              <span>ORDER #{order.order_number}</span>
                              <br />
                              {/* <span>
                                DATE:{" "}
                                {new Date(order.created_at).toLocaleDateString(
                                  "en-ZA"
                                )}
                              </span><br />
                              <span>
                                TIME:{" "}
                                {new Date(order.created_at).toLocaleTimeString(
                                  "en-ZA"
                                )}
                              </span> */}
                              
                            </div>
                            <hr />
                            <small className="fw-bold px-2">Items</small>
                              <div className="w-100"></div>
                              <div className="width-100">
                                {order.order_items?.data &&
                                order.order_items.data.length > 0 ? (
                                  <ul className="listview  flush  py-0 pb-5">
                                    {order.order_items.data.map(
                                      (order_item) => {
                                        return (
                                          <li
                                            className="py-3"
                                            key={`previous-order-item-${order_item.id}`}
                                          >
                                            <div className="row mb-1 px-2">
                                              <div className="col pt-2">
                                                <h3>
                                                  {order_item.quantity} x{" "}
                                                  {order_item.variant.item.name}
                                                </h3>
                                              </div>
                                              <div className="col text-end text-success pt-2">
                                                <b>R {order_item.total}</b>
                                              </div>
                                            </div>

                                            <div className="row mb-1">
                                              <div className="col">
                                                <div className="px-2">
                                                  {order_item.variant.name}
                                                </div>
                                              </div>
                                            </div>

                                            {order_item.item_options?.data &&
                                            order_item.item_options.data
                                              .length > 0
                                              ? order_item.item_options.data.map(
                                                  (item_option) => {
                                                    return (
                                                      <div
                                                        className="row mb-1"
                                                        key={`previous-item-option-${item_option.id}`}
                                                      >
                                                        <div className="col">
                                                          <span className="px-2">
                                                            <i className="bi bi-plus-circle"></i>{" "}
                                                            {
                                                              item_option.option
                                                                .name
                                                            }{" "}
                                                            <small>
                                                              R
                                                              {
                                                                item_option.price
                                                              }
                                                            </small>
                                                          </span>
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                                )
                                              : null}

                                            {order_item.notes && (
                                              <div className="row mb-1">
                                                <div className="col">
                                                  {order_item.notes}
                                                </div>
                                              </div>
                                            )}
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                ) : null}
                              </div>
                              <div className="w-100 position-relative pb-5 mb-3 d-flex flex-row justify-content-between"></div>
                            </div>
                            <div className="card-footer  position-absolute bottom-0 w-100 pb-3 ">
                              <div className="w-100 d-flex justify-content-between">
                                <h3>Total</h3>
                                <h3 class="text-success">R {order.total}</h3>
                              </div>
                              <div className="text-sm text-muted"></div>
                              <div className=" text-muted w-100 text-end">
                                <Link
                                  to={`/order/99897a7f-dd95-4250-bb8a-fb06b175b787/print?invoiceData=${encodeURIComponent(
                                    JSON.stringify({ order: order })
                                  )}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="btn btn-sm btn-primary"
                                >
                                  Print
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </Fragment>
        )}
        <Footer />
      </div>

      <BottomMenu />
      <SidebarPanel />
    </Fragment>
  );
};

export default QueuesPage;
