import { createSlice } from '@reduxjs/toolkit';
import { registerUser, loginAction } from './authActions';

const token = localStorage.getItem('token') ? localStorage.getItem('token') : null
const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
const context = localStorage.getItem('context') ? JSON.parse(localStorage.getItem('context')) : {type: 'User', data: user}

const initialState = {
  loading: false,
  user, 
  token,
  context,
  error: null,
  success: false, 
  showAuthModal: false,
  authModalAs: 'Login'
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        switchContext: (state, action) => {
            state.context = action.payload;
            localStorage.setItem('context', JSON.stringify(action.payload));

        },
        logOut: (state, action) => {
            state.user = null;
            state.token = null;

            localStorage.removeItem('user');
            localStorage.removeItem('token');
        },
        toggleShowAuthModal: (state, action) => {
            state.showAuthModal = !state.showAuthModal;
            state.authModalAs = 'Login';
        },
        setAuthModalAs: (state, action) => {
            state.authModalAs = action.payload;
        }
    },
    extraReducers: {
        // login action
        [loginAction.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [loginAction.fulfilled]: (state, { payload }) => {
            state.showAuthModal = false;
            state.loading = false
            
            state.token = payload.token
            state.user = payload.data
            
            state.context = {
                type: 'User',
                data: payload.data
            }
        },
        [loginAction.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        
        // register user
        [registerUser.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [registerUser.fulfilled]: (state, { payload }) => {
            state.showAuthModal = false;
            state.loading = false
            state.success = true 

            state.user = payload.data
            state.token = payload.token

            state.context = {
                type: 'User',
                data: payload.data
            }
        },
        [registerUser.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
    },
});

// Export the actions
export const { switchContext, logOut, toggleShowAuthModal, setAuthModalAs } = authSlice.actions;


export const selectContext = (state) => {
    return state.context;
}

// Export the reducer
export default authSlice.reducer;