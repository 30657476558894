import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import BottomMenu from '../common/BottomMenu';
import Footer from '../common/Footer';
import SidebarPanel from '../common/SidebarPanel';
import api from '../services/api.service';
import Loader from '../common/Loader';
import AppHeader from '../common/AppHeader';
import NoRecordsFound from '../common/NoRecords';

const CartsPage = () => {
    const { marketId } = useParams();
    
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        console.log(marketId, 'marketId');

        setIsLoading(true);

        api
            .get(`/me/carts`, {
                params: {
                    venue_id: marketId,
                    state: 'new'
                }
            })
            .then((response) => {
                if (response.data && response.data.data && response.data.data.length > 0){
                    const cart = response.data.data[0];
                    navigate(`/carts/${cart.id}`);
                }

                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                
                setIsLoading(false);
            });
    }, [marketId, navigate]);

    return (
        
        <Fragment>
            <AppHeader name="Cart"></AppHeader>

            <div id="appCapsule">
            <div className=" p-5 text-center">
                <div className="col-6">
            <h1>Cart</h1>
        
                <div className="header-large-title mt-5 mb-2">
                    <h1 className="title" >Cart</h1>
                    <h4 className="subtitle">Here's a list of all the orders in your cart.</h4>
                </div>
                {
                    isLoading ? 
                        <Loader />
                    :
                        <NoRecordsFound />
                }
                </div>
                </div>
                <Footer />
            </div>

            <BottomMenu marketId={marketId} />
            <SidebarPanel />
        </Fragment>
    );
}

export default CartsPage