import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import BottomMenu from "../common/BottomMenu";
import Footer from "../common/Footer";
import SidebarPanel from "../common/SidebarPanel";
import AppHeader from "../common/AppHeader";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../common/Loader";
import api from "../services/api.service";
import { fetchActiveCartAction } from "../redux/features/cart/cartActions";
import { selectCartItemCount } from "../redux/features/cart/cartSlice";
import { usePaystackPayment } from "react-paystack";
import { setActiveVenueId } from "../redux/features/app/globalSlice";

const CartDetailsPage = () => {

  const { venueId } = useParams();

  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);


  const cartItemCount = useSelector(selectCartItemCount);
  const isLoading = useSelector((state) => state.cart.loading);
  const cart = useSelector((state) => state.cart.data);

  const [isBusy, setIsBusy] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) {
      navigate("/login");
      return;
    }

    dispatch(fetchActiveCartAction({ venue_id: venueId, user_id: user.id }));
  }, [venueId, user, navigate, dispatch]);

  const handleIncreaseQuantity = (e, order_item) => {
    e.preventDefault();

    setIsBusy(true);

    const quantity = order_item.quantity + 1;

    api
      .put("/order-items/" + order_item.id, { quantity: quantity })
      .then((response) => {
        dispatch(
          fetchActiveCartAction({ venue_id: venueId, user_id: user.id })
        );
        setIsBusy(false);
      })
      .catch((error) => {
        console.error(error);
        setIsBusy(false);
      });
  };

  const handleDecreaseQuantity = (e, order_item) => {
    e.preventDefault();

    if (order_item.quantity < 2) {
      return false;
    }

    setIsBusy(true);

    const quantity = order_item.quantity - 1;

    api
      .put("/order-items/" + order_item.id, { quantity: quantity })
      .then((response) => {
        dispatch(
          fetchActiveCartAction({ venue_id: venueId, user_id: user.id })
        );
        setIsBusy(false);
      })
      .catch((error) => {
        console.error(error);
        setIsBusy(false);
      });
  };

  const handleDeleteOrderItem = (e, order_item) => {
    setIsBusy(true);

    api
      .delete("/order-items/" + order_item.id)
      .then((response) => {
        dispatch(
          fetchActiveCartAction({ venue_id: venueId, user_id: user.id })
        );
        setIsBusy(false);
      })
      .catch((error) => {
        console.error(error);
        setIsBusy(false);
      });
  };

  // const handlePayNow = (e) => {
  //     e.preventDefault();

  //     api
  //         .post(`/carts/${cartId}/pay-now`)
  //         .then((response) => {
  //             window.location.replace(response.data.data);
  //         })
  //         .catch((error) => {
  //             console.error(error);
  //         });
  // }

  // const handleOrderNow = (e) => {
  //     e.preventDefault();

  //     api
  //         .post(`/carts/${cart.id}/order-now`)
  //         .then((response) => {
  //             dispatch(fetchActiveCartAction({venue_id: venueId, user_id: user.id}));

  //             navigate('/queues');
  //         })
  //         .catch((error) => {
  //             console.error(error);
  //         });
  // }

  const [payStackConfig, setPayStackConfig] = useState({});

  useEffect(() => {
    if (cart?.id) {
      var subaccounts = [];

      if (cart.orders && cart.orders.data) {
        subaccounts = cart.orders.data.map((order) => {
          var subaccountData = {
            subaccount: order.vendor.paystack_account?.subaccount_code,
            share: parseInt(order.total * 100),
          };

          return subaccountData;
        });
      }

      var config = {
        reference: new Date().getTime().toString(),
        email: cart.account.email,
        amount: parseInt(cart.item_total * 100),
        split: {
          type: "flat",
          bearer_type: "all-proportional",
          subaccounts: subaccounts,
        },
        currency: "ZAR",
        channels: [
          "card",
          "bank",
          "ussd",
          "qr",
          "mobile_money",
          "bank_transfer",
        ],
        publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
      };

      setPayStackConfig(config);
    }
  }, [cart]);

  // you can call this function anything
  const onSuccess = (reference) => {
    api
      .post(`/carts/${cart.id}/pay-with-paystack`, reference)
      .then((response) => {
        dispatch(
          fetchActiveCartAction({ venue_id: venueId, user_id: user.id })
        );

        navigate(`/venues/${venueId}/queues`);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const initializePayment = usePaystackPayment(payStackConfig);

  const handlePayWithPayStack = () => {
    initializePayment(onSuccess, onClose);
  };

  // const PaystackHookExample = () => {

  //     api
  //         .post(`/carts/${cartId}/pay-now`)
  //         .then((response) => {

  //         })
  //         .catch((error) => {
  //             console.error(error);
  //         });

  //     const initializePayment = usePaystackPayment(config);
  //     return (
  //       <div>
  //           <button onClick={() => {
  //               initializePayment(onSuccess, onClose)
  //           }}>Paystack Hooks Implementation</button>
  //       </div>
  //     );
  // };

  useEffect(() => {
    dispatch(setActiveVenueId(venueId));
  }, [dispatch, venueId]);

  return (
    <Fragment>
      <AppHeader ></AppHeader>

      <div id="appCapsule">
        <div className="row d-flex justify-content-center">
          <div className="col-md-5 col-sm-12">
      <div className="w-100 p-5 text-center">
            <h1>Cart</h1>
        </div>

        {!cart && isLoading ? (
          <Loader />
        ) : cart.orders && cart.orders.data && cart.orders.data.length > 0 ? (
          <Fragment>
            {cart.orders.data.map((order) => {
              return (
                <div className="section m-1" key={`order-${order.id}`}>
                  <div className="card p-2">
                    <div className="card-header">{order.vendor.name}</div>
                    <div className="card-body px-0 py-0">
                      {order.order_items &&
                      order.order_items.data &&
                      order.order_items.data.length > 0 ? (
                        <ul className="listview flush ">
                          {order.order_items.data.map((order_item) => {
                            return (
                              <li
                                className="p-2"
                                key={`order-item-${order_item.id}`}
                              >
                                <div className="row mb-1">
                                  <div className="col">
                                    <div>
                                      {order_item.quantity} x{" "}
                                      {order_item.variant.item.name}
                                    </div>
                                  </div>
                                  <div className="col text-end">
                                    <small>R{order_item.total}</small>
                                  </div>
                                </div>

                                <div className="row mb-1">
                                  <div className="col">
                                    <div className="px-2">
                                      {order_item.variant.name}
                                    </div>
                                  </div>
                                </div>

                                {order_item.item_options &&
                                order_item.item_options.data &&
                                order_item.item_options.data.length > 0
                                  ? order_item.item_options.data.map(
                                      (item_option) => {
                                        return (
                                          <div
                                            className="row"
                                            key={`item-option-${item_option.id}`}
                                          >
                                            <div className="col">
                                              <span className="px-2">
                                                <i className="bi bi-plus-circle"></i>{" "}
                                                {item_option.option.name}{" "}
                                                <small>
                                                  R{item_option.price}
                                                </small>
                                              </span>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )
                                  : null}

                                {order_item.notes && (
                                  <div className="card-tex p-2">
                                    {order_item.notes}
                                  </div>
                                )}

                                <div className="row pt-1">
                                  <div className="col">
                                    <div className="stepper stepper-sm stepper-dark">
                                      <button
                                        className="stepper-button stepper-down w-25"
                                        onClick={(e) =>
                                          handleDecreaseQuantity(e, order_item)
                                        }
                                        disabled={isBusy}
                                      >
                                        -
                                      </button>
                                      <input
                                        type="text"
                                        className="form-control w-100"
                                        value={order_item.quantity}
                                        disabled
                                      />
                                      <button
                                        className="stepper-button stepper-up w-25"
                                        onClick={(e) =>
                                          handleIncreaseQuantity(e, order_item)
                                        }
                                        disabled={isBusy}
                                      >
                                        +
                                      </button>
                                    </div>
                                  </div>
                                  <div className="col text-end">
                                    <div
                                      onClick={(e) =>
                                        handleDeleteOrderItem(e, order_item)
                                      }
                                      disabled={isBusy}
                                    >
                                    <ion-icon name="trash-bin" style={{ color: "red", fontSize: "20px" }}></ion-icon>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        <p>No order items found</p>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="section m-1">
              <div className="card">
                <ul className="listview flush transparent simple-listview p-3">
                  <li>
                    Items <span className="">{cartItemCount}</span>
                  </li>

                  {cart.adjustments &&
                  cart.adjustments.data &&
                  cart.adjustments.data.length > 0
                    ? cart.adjustments.data.map((adjustment) => {
                        return (
                          <li key={`adjustments.${adjustment.id}`}>
                            {adjustment.label}{" "}
                            <span className="">R{adjustment.amount}</span>
                          </li>
                        );
                      })
                    : null}

                  <li className=" fw-bold ">
                    Total<span className="">R{cart.item_total}</span>
                  </li>
                </ul>
              </div>
            </div>

            {/* <div className="section mb-2">
                                    <button className="btn btn-dark btn-block " onClick={(e) => handleOrderNow(e)}  disabled={isBusy} >Order Now</button>
                                </div> */}

            <div className="section m-1 ">
              <button
                className="btn btn-dark  btn-lg btn-block rounded-full mb-1 position "
                onClick={(e) => handlePayWithPayStack(e)}
                disabled={isBusy}
              >
                <ion-icon name="card"></ion-icon> &nbsp; Pay Now
              </button>
              <Link
                className="btn btn-primary btn-lg btn-block rounded-full mb-1 position"
                to={`/venues/${venueId}/vendors`}
              >
                <ion-icon name="fast-food"></ion-icon> &nbsp; Continue Shopping
              </Link>
            </div>

            {/* <div className="section mb-2">
                                    <a href="!#" className="btn btn-dark btn-block " onClick={(e) => handlePayNow(e)} >Pay Now</a>
                                </div> */}
          </Fragment>
        ) : (
          <div className="section mt-3 mb-3">
            <div className="card">
              <div className="card-body">
                <p className="card-text">There is nothing in your cart.</p>
              </div>
              <div className="card-footer">
                <Link
                  to={`/venues/${venueId}/vendors`}
                  className="btn btn-dark btn-block"
                >
                  Continue shopping
                </Link>
              </div>
            </div>
          </div>
        )}
        </div>
</div>
        <Footer />
      </div>

      <BottomMenu />
      <SidebarPanel />
    </Fragment>
  );
};

export default CartDetailsPage;
