import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../PrintInvoicePage.css';  

const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

const handlePrint = (invoiceData) => {
  return new Promise((resolve) => {
    document.title = `receipt: #${invoiceData.order_number} vendor: ${invoiceData.vendor.name} for ${invoiceData.user.name}`
    setTimeout(() => {
     
      try {
        // Print for Safari browser
        document.execCommand('print', false, null)
      } catch (error) {
        window.print()
      }
      resolve();
    }, 1000);
  }); 
};

const PrintInvoicePage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const invoiceData = JSON.parse(queryParams.get('invoiceData')).order;
  const orderDate = invoiceData.order_date ? invoiceData.order_date :  JSON.parse(queryParams.get('invoiceData')).created_at;
  const [isLoading, setIsLoading] = useState(!invoiceData);

  useEffect(() => {
    window.onload = () => {
      if (invoiceData && !isLoading) {
        setIsLoading(false);
        handlePrint(invoiceData).then(() => {
        
         if(!isMobile()){
            window.close();
         }else{
          window.history.back();
         }
          
        });

      }
    };
  }, [isLoading, invoiceData]);
  

  if (isLoading) {
    return <div>Loading...</div>;  // You can replace this with a proper loading component or spinner
  }

  return (
    <div className="invoice-wrapper">
      <div className="invoice">
        <div className="invoice-header">
         <div className="w-50 text-start d-flex align-items-center">
         <img src="/assets/img/logo.png" alt="Logo" className="logo" width="150px" height="auto"/>
         <h3 className='pt-1'>KwikQ</h3>
         </div>
        <div className='text-end'>
        <h3 className="order-number">Order Number: {invoiceData.order_number}</h3>
          <h3 className="order-date">Order Date: {new Date(orderDate).toLocaleString("en-ZA")}</h3>
        </div>
        </div>
        <hr />
        <div className="d-flex justify-content-between w-100">
        <h3 className="vendor">Buyer: {invoiceData.user.name}</h3>
        <h3 className="vendor">Vendor: {invoiceData.vendor.name}</h3>
        </div>
        
       

        <hr />
        <h3>Items</h3>
        <hr />
        <div className="items">
          <div className="order-items header">
          <p className="item-name text-start">Item Name</p>
          <p className="price text-center">Price</p>
          <p className="quantity text-center">Quantity</p>
          <p className="item-total text-center">Total</p>
          </div>
          {invoiceData.order_items.data.map((item) => (
            <div key={item.id} className="item">
             
              <div className="order-items">
              <div className="item-name"><b className='fw-bold'>{item.variant.item.name} ({item.variant.name})</b><br/>
              {item.variant.item.description}
              </div>
              <p className="price">R {item.price}</p>
              <p className="quantity">{item.quantity}</p>
              <p className="item-total"> R {item.total}</p>
              </div>
             
            </div>
          ))}
        </div>

        <p className="total">Order Total: R {invoiceData.total}</p>
      </div>
    </div>
  );
};

export default PrintInvoicePage;
