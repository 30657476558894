import React, { Fragment, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import BottomMenu from '../common/BottomMenu';
import Footer from '../common/Footer';
import SidebarPanel from '../common/SidebarPanel';
import { useDispatch, useSelector } from 'react-redux';
import { switchContext } from '../redux/features/auth/authSlice';
import AppHeader from '../common/AppHeader';
import api from '../services/api.service';
import Loader from '../common/Loader';
import { logOut } from '../redux/features/auth/authSlice';


const ProfilePage = () => {
    
    const { venueId } = useParams();

    const [ profile, setProfile ] = useState({});
    // const [ orders, setOrders ] = useState({});

    const [ isLoading, setIsLoading ] = useState(false);

    const { context, user } = useSelector(
        (state) => state.auth
    );

    const navigate = useNavigate();
    const dispatch = useDispatch();  

    const handleLogOut = (e) => {
        // e.preventDefault();

        dispatch(logOut());
        navigate('/');
        
    }
    const handleSwitchContext = (e, type, data) => {
        e.preventDefault();

        const context = {
            type: type,
            data: {
                id: data.id,
                name: data.name
            }
        }

        dispatch(switchContext(context));

        if (type === 'Vendor' && data?.venue_id){
            navigate(`/venues/${data.venue_id}/queues`);
        } else {
            navigate(`/venues`);
        }
    }

    useEffect(() => {
        if (!user) {
            navigate('/login');
        }
        
        setIsLoading(true);

        api
            .get('/me', { params: { context } })
            .then((response) => {
                setProfile(response.data.data);
                // setOrders(response.data.orders);

                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                
                setIsLoading(false);
            });
    }, [user, navigate, context]);

    return (
        <Fragment>
            <AppHeader name="My Account" ></AppHeader>

            <div id="appCapsule">
                {
                    isLoading ? 
                        <Loader />
                    :
                        profile &&
                            <Fragment >
                                <div className="p-3 text-center">
                                    {
                                        context.type === 'Vendor' ?
                                            <div >
                                                <ion-icon name="person-circle-sharp" style={{ fontSize: "80px" }}></ion-icon>
                                                <h3>Welcome back </h3> <h1>{ context.data.name }</h1>
                                                <div className="card-text"><b>Supporting your community since:</b> <br /> { new Date(profile.created_at).toLocaleDateString('en-us', { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' }) }</div>
                                            </div>
                                    :
                                             <div >
                                                <ion-icon name="person-circle-sharp" style={{ fontSize: "80px" }}></ion-icon>
                                                <h3>Welcome back </h3> <h1>{ context.data.name }</h1>
                                                <div className="card-text"><b>Supporting your community since:</b> <br /> { new Date(profile.created_at).toLocaleDateString('en-us', { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' }) }</div>
                                            </div>
                                            
                                    }
                                </div>

                              
                               <div className="d-flex justify-content-center w-100">
                                <div className="col-md-6 col-sm-12 d-flex flex-column justify-content-center w-md-50 w-sm-100 p-5 ">
                                    <button className="btn btn-dark text-white w-sm-100 w-md-50 rounded-full mb-2" onClick={(e) => handleLogOut(e) } >Log Out</button>
                                    <Link className="btn btn-dark text-white w-sm-100 w-md-50 rounded-full mb-2" to={`/venues/${venueId}/queues`} >View Orders</Link>
                                    <div className="rounded shadow p-5 mt-3">
                                        <div className="text-center">
                                        <img src="/assets/img/logo.png" className='img-fluid' width="150" alt="logo" />
                                        <p>Experience the future of food ordering with KwikQ. No app download required. Effortlessly search, order, and pay for meals online. It's hassle-free, convenient, and delightful. Enjoy seamless transactions, satisfied customers, and thriving businesses. Embrace a culinary journey with KwikQ—satisfaction just a click away!</p>
                                        </div>
                                    </div>
                                </div>
                               </div>

                                    
                                <div className="section full mb-2">
                                    <div className="tab-content">

                                    </div>
                                </div>
                                <div className="container-fluid">
                                <div className='w-100 d-flex justify-content-center'>
                                    <div className=" w-100 d-flex justify-content-center">
                                    <div className="section mt-2 mb-3">
                                    <Link href="!#" className="btn btn-primary text-white w-sm-100 w-md-50 rounded-full" data-bs-toggle="offcanvas" data-bs-target="#actionSwitchAppContext">
                                        <div className="col-12">
                                            { context.type === 'Vendor' ? "Login to personal account" : 'Login in as  vendor' }
                                        </div>
                                    </Link>        

                                    <div className="offcanvas offcanvas-bottom action-sheet profile-Switch" tabIndex="-1" id="actionSwitchAppContext">
                                        <div className="offcanvas-header">
                                            <h5 className="offcanvas-title">Switch to</h5>
                                        </div>
                                        <div className="offcanvas-body">
                                            <ul className="listview image-listview">
                                                <li>
                                                    <a href="!#" className="item" onClick={(e) => handleSwitchContext(e, 'User', {id: profile.id, name: profile.name})} data-bs-dismiss="offcanvas" >
                                                        <img src="/assets/img/sample/avatar/avatar1.jpg" alt="" className="image" />
                                                        <div className="in">
                                                            <div>
                                                                <footer>Personal account</footer>
                                                                { profile.name }
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>
                                                {
                                                    profile.vendors && profile.vendors.data && profile.vendors.data.length > 0 ?
                                                        profile.vendors.data.map((vendor) => {
                                                            return (
                                                                <li key={`profile.vendors.${vendor.id}`}>
                                                                    <a href="!#" className="item" onClick={(e) => handleSwitchContext(e, 'Vendor', vendor)} data-bs-dismiss="offcanvas" >
                                                                        <img src="/assets/img/sample/avatar/avatar7.jpg" alt="" className="image" />
                                                                        <div className="in">
                                                                            <div>
                                                                                <footer>Vendor</footer>
                                                                                { vendor.name }
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </li>
                                                            )
                                                        })
                                                    :
                                                        null
                                                }
                                            </ul>
                                        </div>
                                    </div>    
                                </div>

                                    </div>
                                </div>
                                </div>
                            </Fragment>
                        
                }

                <Footer />
            
            </div>

            {
                venueId && 
                    <BottomMenu />
            }
            
            <SidebarPanel />
        </Fragment>
    );
}

export default ProfilePage