import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const fetchActiveVenueAction = createAsyncThunk(
  'global.fetchActiveVenue',
  async ({id}, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      const { data } = await axios.get(
        '/venues/' + id,
        {},
        config
      )

      return data.data;
    } catch (error) {
        // return custom error message from backend if present
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message)
        } else {
            return rejectWithValue(error.message)
        }
    }
  }
)